import "@/styles/globals.css";
import { NextUIProvider, Spinner } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { fontSans, fontMono } from "@/config/fonts";
import type { AppProps } from "next/app";
// import { useBackend } from "@/hooks/useBackend";
import { createContext, useEffect, useState } from "react";
import { CreateModal, DynamicModal, RegisterModal } from "@/components/DynamicModal";
import { SidebarMain } from "@/components/Navigation/Sidebar";
import { useRouter, Router } from "next/router";
import dynamic from "next/dynamic";
import {useTheme} from "next-themes";
import { ToastContainer } from 'react-toastify';
import { BackendResponse, useBackend, backendSend } from "@/hooks/useBackend";

import { RefreshSession } from "@/hooks/SessionContext";
import { FiAlertTriangle } from "react-icons/fi";
import { AreFormChangesPending, SetFormChangesPending } from "@/backend/sessionStorage";
import OutdatedBrowserWarning from "@/components/OutdatedBrowserWarning";
import ErrorBoundary from "@/components/ErrorBoundary";

import NextNProgress from 'nextjs-progressbar';
import { NotificationContextProvider, notificationStorage } from "@/backend/notifications";
// import { AddEventListeners } from "@/backend/bootstrap";
// import { OnNavigationStarted } from "@/backend/FormHandlers";
import BuildCheck from "@/components/BuildCheck";

const localBuild = "e2c412e";

export function App({ Component, pageProps }: AppProps) {
	const router = useRouter();

	function NavigateAnyway(params: any) {
		SetFormChangesPending(false);
		
		router.push(params.url);
	};

	useEffect(() => {
		function OnNavigationStarted(url: any, props: any) {
			if (AreFormChangesPending()) {
				// router.events.emit("routeChangeError", "form_changes_pending");
				Router.events.emit("routeChangeError", "form_changes_pending", "form_changes_pending_2", props);
				// console.log(router.events);

				CreateModal({
					title: "Unsaved Changes",
					icon: <FiAlertTriangle />,
					message: "There are unsaved changes. Press confirm to leave the page anyway, or cancel to return.",
					callback: NavigateAnyway,
					callbackParams: { url: url }
				})

				throw "Preventing route change due to unsaved form data. Ignore this error.";
			}
		}

		Router.events.on('routeChangeStart', OnNavigationStarted);
	 
		// If the component is unmounted, unsubscribe
		// from the event with the `off` method:
		return () => {
			Router.events.off('routeChangeStart', OnNavigationStarted);
		}
	}, [router])

	return (
		<NextUIProvider navigate={router.push}>
			<NextThemesProvider attribute="class" defaultTheme="system">
				<ErrorBoundary>
					<AppContent Component={Component} pageProps={pageProps} />
				</ErrorBoundary>
			</NextThemesProvider>
		</NextUIProvider>
	);
}

function AppContent({ Component, pageProps }: any) {
	const router = useRouter(); 

	// const [ sessionData, setSessionData ] = useState<any>({ loggedIn: null });

	const [ isLoggedIn, setLoggedIn ] = useState<boolean | null>(null);
	// const { isLoggedIn } = useSessionContext();
	const { resolvedTheme, setTheme } = useTheme();
	const colorScheme = resolvedTheme === "dark" ? "dark" : "light";

	useEffect(() => {
		RefreshSession(setLoggedIn, false);
	}, [])

	if (isLoggedIn === null) {
		return <></>
	};

	if (isLoggedIn === true) {
		// stuff to do when logged in
		if (router.pathname === "/login") {
			setTimeout(router.push, 1000, "/services");

			return <div className="flex justify-center items-center p-6"><Spinner /></div>;
		}
	} else {
		if (router.pathname !== "/login" && router.pathname !== "/reset-password") {
			setTimeout(router.push, 1000, "/login");

			return <div className="flex justify-center items-center p-6"><Spinner /></div>;
		}
	}

	return (
		<>
			<NextNProgress color="#29D" startPosition={0.3} stopDelayMs={200} height={3} showOnShallow={false} />
			<OutdatedBrowserWarning />
			<BuildCheck localBuild={localBuild} />
			<div className="flex flex-row justify-center gap-0 min-h-full w-full">
				<div className={`hidden grow min-h-full bg-[${colorScheme === 'dark' ? '#000000' : '#000a16'}] 2xl:flex`}></div>
				<div id="pageContainer" className={"flex grow min-h-full w-full max-w-[1920px] pagecontainer-"+colorScheme}>
					<NotificationContextProvider value={notificationStorage.notifications}>
						<SidebarMain shouldDisplay={isLoggedIn} />
					</NotificationContextProvider>
					<Component {...pageProps} />
				</div>
				<div className={"grow min-h-full hidden 2xl:min-w-[4rem] 2xl:flex pagecontainer-"+colorScheme}></div>
			</div>
			<DynamicModal />
			<ToastContainer theme={colorScheme} />
		</>
	)
	// </UserSessionContext.Provider>
}

export const fonts = {
	sans: fontSans.style.fontFamily,
	mono: fontMono.style.fontFamily,
};

export default dynamic(() => Promise.resolve(App), {
	ssr: false,
});